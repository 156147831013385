import { useLocation, useNavigate } from "react-router-dom";
import { DashboardHeaderComponent } from "../components/admin/DashboardHeaderComponent";
import { FooterComponent } from "../components/client/FooterComponent";
import { PendingQuote } from "../../domain/models/PendingQuote";
import { ProductCart } from "../../domain/models/ProductCart";
import { useState } from "react";
import { NewProcessedQuote } from "../../domain/models/NewProcessedQuote";
import { faFloppyDisk, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductsRepository } from "../../domain/repository/ProductsRepository";
import { QuotesRepository } from "../../domain/repository/QuotesRepository";
import { useAdminModelController } from "../hook/useAdminModelController";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import {
  getFormattedDate,
  getFormattedDateWithHours,
} from "../../utils/dateUtils";
import { AuthRepository } from "../../domain/repository/AuthRepository";
import { FirebaseEmail } from "../../domain/models/FirebaseEmail";
import { LoaderView } from "../components/LoaderView";
import ConfirmDialogComponent, {
  DialogType,
} from "../components/admin/ConfirmDialogComponent";
import logo from "../../assets/images/logo-dap-rent.png";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Link,
  pdf,
  Font,
} from "@react-pdf/renderer";

import { saveAs } from "file-saver";

import { doc } from "firebase/firestore";

export enum DiscountType {
  Percentage,
  Amount,
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFF",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
  },
  headerSection: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 20,
  },
  companyInfo: {
    flex: 1,
    paddingRight: 10,
  },
  companyName: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  companyData: {
    fontSize: 10,
    color: "#7d7d7d",
    marginBottom: 2,
  },

  clientRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  clientInfoContainer: {
    borderWidth: 1,
    borderColor: "#e0e0e0",
    borderStyle: "solid",
    padding: 5,
    marginBottom: 10,
    alignSelf: "flex-start",
    flex: 1,
    marginRight: 10,
  },
  eventInfoContainer: {
    borderWidth: 1,
    borderColor: "#e0e0e0",
    borderStyle: "solid",
    padding: 5,
    marginBottom: 10,
    alignSelf: "flex-start",
    flex: 1,
  },
  tableHeader: {
    marginTop: 30,
    flexDirection: "row",
    backgroundColor: "#e0e0e0",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    borderBottomStyle: "solid",
    padding: 5,
    alignItems: "center",
    textAlign: "center",
  },
  tableRow: {
    flexDirection: "row",
    marginTop: 2,
    paddingBottom: 2,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
    borderBottomStyle: "solid",
    alignItems: "center",
    textAlign: "center",
  },
  tableCol: {
    flex: 1,
    paddingRight: 10,
    fontSize: 12,
  },
  imageCol: {
    height: 40,
    objectFit: "contain",
  },
  textRight: {
    textAlign: "right",
  },
  summaryPriceSection: {
    borderWidth: 1,
    borderColor: "#e0e0e0",
    borderStyle: "solid",
    marginTop: 50,
    marginLeft: "auto",
    width: "40%",
    textAlign: "right",
  },

  productTitle: {
    fontFamily: "Open Sans",
    fontWeight: 600,
    fontSize: 16,
  },
  productDescription: {
    fontFamily: "Open Sans",
    fontSize: 14,
  },
  title: {
    fontFamily: "Open Sans",
    textAlign: "center",
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 20,
  },
  quoteNumber: {
    fontWeight: "bold",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 20,
  },
  sectionTitle: {
    fontFamily: "Open Sans",
    fontSize: 18,
    fontWeight: "bold",
    marginVertical: 10,
  },
  productList: {
    fontSize: 12,
    marginVertical: 10,
  },
  productItem: {
    marginVertical: 10,
  },
  productImage: {
    width: 150,
    height: 150,
    marginTop: 30,
    marginBottom: 10,
    objectFit: "contain",
  },
  summary: {
    fontSize: 14,
    textAlign: "right",
    marginVertical: 5,
    fontFamily: "Open Sans",
    fontWeight: "bold",
  },

  summaryPriceContainer: {
    textAlign: "right",
    marginVertical: 5,
    marginRight: 5,
  },
  summaryLabel: {
    fontSize: 10,
    color: "#7d7d7d",
  },
  summaryValue: {
    fontSize: 12,
    color: "#000",
    fontWeight: "bold",
  },
  footer: {
    fontSize: 10,
    marginTop: 100,
    marginBottom: 20,
    textAlign: "center",
  },
  logoHeader: {
    height: 70,
    alignItems: "flex-start",
  },
  logo: {
    width: 100,
    marginVertical: 20,
    alignSelf: "center",
  },
});

export default function PendingQuoteDetailView({
  authRepository,
  productRepository,
  quotesRepository,
}: {
  authRepository: AuthRepository;
  productRepository: ProductsRepository;
  quotesRepository: QuotesRepository;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const quote: PendingQuote = location.state.quote;

  const [newPrices, setNewPrices] = useState<ProductCart[]>(quote.cart);
  const [description, setDescription] = useState<string>("");
  const [shippingCost, setShippingCost] = useState<string>("0");
  const [discountValue, setDiscountValue] = useState<string>("0");
  const [discountType, setDiscountType] = useState<DiscountType>(
    DiscountType.Percentage
  );
  const [editPrice, setEditPrice] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [confirmDeleteDialog, setConfirmDeleteDialog] =
    useState<boolean>(false);
  const [confirmQuoteDialog, setConfirmQuoteDialog] = useState<boolean>(false);
  const {
    handleProcessPendingQuote,
    handleUploadPDFQuote,
    handleDeletePendingQuote,
  } = useAdminModelController(productRepository, quotesRepository);

  Font.register({
    family: "Open Sans",
    fonts: [
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
      },
      {
        src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
        fontWeight: 600,
      },
    ],
  });

  const handleChangeProductPrice = (productId: string, newPrice: string) => {
    const updatedPrices = newPrices.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          price: parseFloat(newPrice),
        };
      }
      return item;
    });

    setNewPrices(updatedPrices);
  };

  const onConfirm = async () => {
    setShowLoading(true);
    setConfirmQuoteDialog(false);
    const doc = QuoteComponent();
    const array: any = [];
    const asPdf = pdf(array);
    asPdf.updateContainer(doc);
    const pdfBlob: Blob = await asPdf.toBlob();

    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().slice(0, 10); // Formato data YYYY-MM-DD
    const formattedTime =
      currentDate.getHours() + "" + currentDate.getMinutes(); // Formato ora hhmm

    const fileName = `${quote.name}_${formattedDate}_${formattedTime}.pdf`;

    const pdfFile = new File([pdfBlob], fileName, {
      type: "application/pdf",
    });

    var pdfUrl = await handleUploadPDFQuote(quote.email, pdfFile, fileName);
    if (pdfUrl !== "") {
      let discount = parseFloat(discountValue);
      if (
        discount < 0 ||
        (discountType === DiscountType.Percentage && discount > 100) ||
        (discountType === DiscountType.Amount &&
          discount > parseFloat(calculateTotalWithTax()))
      ) {
        setShowLoading(false);
        showErrorToast("Inserisci un valore di sconto valido e riprova");
        return;
      }
      let processedQuote: NewProcessedQuote = {
        toEmail: quote.email,
        toName: quote.name,
        phone: quote.phone,
        text: description,
        date: Date.now(),
        cart: newPrices,
        shippingPrice: parseFloat(shippingCost.toString()),
        shippingAddress: quote.shippingAddress,
        shippingCity: quote.shippingCity,
        shippingCap: quote.shippingCap,
        deliveryDate: quote.deliveryDate,
        eventDate: quote.eventDate,
        returnDate: quote.returnDate,
        totalPrice: parseFloat(calculateTotalDiscounted().replace(",", ".")),
        pdfUrl: pdfUrl,
      };
      const html = await generateEmailContent(pdfUrl);
      let firebaseEmail: FirebaseEmail = {
        to: quote.email,
        message: {
          subject: "Il tuo preventivo di DapRent è pronto!",
          html: html,
        },
      };

      try {
        const result = await handleProcessPendingQuote(
          quote.id,
          processedQuote,
          firebaseEmail
        );

        if (result) {
          setShowLoading(false);
          showSuccessToast("Il preventivo è stato confermato!");
          navigate("/dashboard/pendingQuotes");
        } else {
          setShowLoading(false);
          showErrorToast(
            "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
          );
        }
      } catch (error) {
        setShowLoading(false);
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
        );
      }
    } else {
      setShowLoading(false);
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
      );
    }
  };

  const calculateTotal = (): number => {
    let total = 0;

    newPrices.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });
    var shipping = shippingCost != "" ? shippingCost : "0";
    total += parseInt(shipping);

    return total;
  };

  const calculateTax = (): string => {
    let total = 0;

    newPrices.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });
    var shipping = shippingCost != "" ? shippingCost : "0";
    total += parseInt(shipping);

    return (total * 0.22).toFixed(2).replace(".", ",");
  };

  const onDelete = async () => {
    setShowLoading(true);
    try {
      const result = await handleDeletePendingQuote(quote.id);

      if (result) {
        setShowLoading(false);
        showSuccessToast("Il preventivo è stato eliminato!");
        navigate("/dashboard/pendingQuotes");
      } else {
        setShowLoading(false);
        showErrorToast(
          "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
        );
      }
    } catch (error) {
      setShowLoading(false);
      showErrorToast(
        "Qualcosa è andato storto. Ti preghiamo di riprovare più tardi."
      );
    }
  };

  const calculateTotalWithTax = (): string => {
    let total = 0;

    newPrices.forEach((product: ProductCart) => {
      total += product.price * product.quantity;
    });
    var shipping = shippingCost != "" ? shippingCost : "0";
    total += parseInt(shipping);

    let tax = total * 0.22;
    return (total + tax).toFixed(2).replace(".", ",");
  };

  const calculateTotalDiscounted = (): string => {
    var totalDiscounted = parseFloat(calculateTotalWithTax().replace(",", "."));
    var discountAmount =
      discountType === DiscountType.Percentage
        ? (totalDiscounted * parseFloat(discountValue)) / 100
        : parseFloat(discountValue);
    totalDiscounted -= discountAmount;
    return totalDiscounted.toFixed(2).replace(".", ",");
  };

  async function generateEmailContent(pdfUrl: string) {
    let emailHTML = `<div style="color:black; background-color:white;">`;
    emailHTML += `<p style="color:black;">${description.replace(
      /\n/g,
      "<br>"
    )}</p>`;
    emailHTML += `<br><div style="color:black;"><a href="${pdfUrl}" target="_blank">Visualizza il preventivo</a><br>`;
    return emailHTML;
  }

  const QuoteComponent = () => {
    const productsTotalPrice = calculateTotal();
    const taxes = calculateTax();
    const productsTotalPriceWithTax = calculateTotalWithTax();
    const totalDiscounted = calculateTotalDiscounted();

    let discountPercentage = 0;
    let discountAmount = 0;

    if (parseFloat(discountValue) > 0) {
      discountPercentage =
        discountType === DiscountType.Percentage
          ? parseFloat(discountValue.replace(",", "."))
          : (parseFloat(discountValue.replace(",", ".")) * 100) /
            parseFloat(productsTotalPriceWithTax.replace(",", "."));

      discountAmount =
        discountType === DiscountType.Percentage
          ? (parseFloat(productsTotalPriceWithTax.replace(",", ".")) *
              parseFloat(discountValue.replace(",", "."))) /
            100
          : parseFloat(discountValue.replace(",", "."));
    }

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <View style={styles.headerSection}>
              <View style={styles.companyInfo}>
                <Text style={styles.companyName}>Dap Rent</Text>
                <Text style={styles.companyData}>P.IVA: 03740990043</Text>
                <Text style={styles.companyData}>
                  Via Macallè, 33/A, 12045 Fossano CN
                </Text>
                <Text style={styles.companyData}>
                  daprent.preventivi@gmail.com | +39 347 468 9592
                </Text>
              </View>
              <Image src={logo} style={styles.logoHeader} />
            </View>

            <Text style={styles.quoteNumber}>Preventivo n° {Date.now()}</Text>
            <View style={styles.clientRow}>
              <View style={styles.clientInfoContainer}>
                <Text style={styles.companyName}>Cliente</Text>
                <Text style={styles.companyData}>{quote.name}</Text>
                <Text style={styles.companyData}>
                  {quote.address}, {quote.cap}, {quote.city}
                </Text>
                <Text style={styles.companyData}>{quote.phone}</Text>
                <Text style={styles.companyData}>{quote.email}</Text>
              </View>
              <View style={styles.eventInfoContainer}>
                <Text style={styles.companyName}>Evento</Text>
                <Text style={styles.companyData}>
                  Spedizione: {quote.shippingAddress}
                </Text>
                <Text style={styles.companyData}>
                  Data spedizione: {getFormattedDate(quote.deliveryDate)}
                </Text>
                <Text style={styles.companyData}>
                  Data evento: {getFormattedDate(quote.eventDate)}
                </Text>
                <Text style={styles.companyData}>
                  Data riconsegna: {getFormattedDate(quote.returnDate)}
                </Text>
              </View>
            </View>

            <View style={styles.tableHeader}>
              <Text style={[styles.tableCol]}>Immagine</Text>
              <Text style={styles.tableCol}>Prodotto</Text>
              <Text style={styles.tableCol}>Quantità</Text>
              <Text style={[styles.tableCol, styles.textRight]}>Prezzo</Text>
              <Text style={[styles.tableCol, styles.textRight]}>Importo</Text>
            </View>

            {newPrices.map((product) => (
              <View style={styles.tableRow} key={product.id}>
                <Image
                  style={[styles.tableCol, styles.imageCol]}
                  src={product.image}
                />
                <Text style={styles.tableCol}>{product.title}</Text>
                <Text style={styles.tableCol}>{product.quantity}</Text>
                <Text style={[styles.tableCol, styles.textRight]}>
                  ${product.price.toFixed(2)}
                </Text>
                <Text style={[styles.tableCol, styles.textRight]}>
                  ${(product.price * product.quantity).toFixed(2)}
                </Text>
              </View>
            ))}

            <View style={styles.summaryPriceSection}>
              <Text style={styles.summaryPriceContainer}>
                <Text style={styles.summaryLabel}>Subtotale: </Text>
                <Text style={styles.summaryValue}>
                  {productsTotalPrice.toFixed(2).replace(".", ",")}€
                </Text>
              </Text>

              <Text style={styles.summaryPriceContainer}>
                <Text style={styles.summaryLabel}>Costi di spedizione: </Text>
                <Text style={styles.summaryValue}>
                  {parseFloat(shippingCost).toFixed(2).replace(".", ",")}€
                </Text>
              </Text>
              <Text style={styles.summaryPriceContainer}>
                <Text style={styles.summaryLabel}>Totale IVA: </Text>
                <Text style={styles.summaryValue}>{taxes}€</Text>
              </Text>
              <Text style={styles.summaryPriceContainer}>
                <Text style={styles.summaryLabel}>Offerta cliente: </Text>
                <Text style={styles.summaryValue}>
                  {parseFloat(discountValue) > 0
                    ? totalDiscounted
                    : productsTotalPriceWithTax}
                  €
                </Text>
              </Text>
            </View>

            <Text style={styles.footer}>
              Grazie per aver utilizzato il nostro servizio. Per ulteriori
              informazioni non esiti a contattarci telefonicamente al numero
              3333333333 o tramite email rispondendo a questo preventivo o
              inviandone una nuova all'indirizzo daprent.preventivi@gmail.com
            </Text>
            <Image src={logo} style={styles.logo} />
          </View>
        </Page>
      </Document>
    );
  };
  if (showLoading) {
    return <LoaderView />;
  }
  return (
    <div className="w-full min-h-full">
      <DashboardHeaderComponent authRepository={authRepository} />
      <main className="mx-auto w-full px-6 md:px-24">
        <div className="justify-between border-b border-gray-200 my-4 md:my-10">
          <div className="flex my-3">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900">
              Dettaglio preventivo
            </h1>
            <button
              type="button"
              className="ml-auto rounded-md bg-red-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-red-800"
              onClick={() => setConfirmDeleteDialog(true)}
            >
              Elimina preventivo
            </button>
          </div>
          <p className="mt-5 text-sm leading-5 text-gray-500">
            <span>{getFormattedDateWithHours(quote.date)}</span>
          </p>
          <h3 className="mt-2">
            <span className="font-bold">Cliente:</span> {quote.name}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Email:</span> {quote.email}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Indirizzo di spedizione:</span>{" "}
            {quote.shippingAddress} {quote.shippingCity} {quote.shippingCap}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data consegna:</span>{" "}
            {getFormattedDate(quote.deliveryDate)}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data evento:</span>{" "}
            {getFormattedDate(quote.eventDate)}
          </h3>
          <h3 className="mt-2">
            <span className="font-bold">Data ritiro:</span>{" "}
            {getFormattedDate(quote.returnDate)}
          </h3>

          <label
            htmlFor="cart"
            className="font-bold block text-sm leading-6 text-gray-900 mt-10"
          >
            Carrello
          </label>
          <div
            className="mt-6 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6 w-full"
            style={{ maxHeight: "400px", overflowY: "auto" }}
          >
            {quote.cart.length > 0 ? (
              quote.cart.map((product: ProductCart, index: number) => (
                <div
                  className="flex flex-col rounded-lg bg-white sm:flex-row"
                  key={product.id}
                >
                  <img
                    className="m-2 h-24 min-w-28 rounded-md border object-contain object-center"
                    src={product.image}
                    alt="product"
                  />
                  <div className="flex w-full flex-col px-4 py-4">
                    <span className="font-semibold">{product.title}</span>
                    <span className="float-right text-gray-400">
                      Quantità: {product.quantity}
                    </span>
                    <div className="flex space-x-3 ">
                      {editPrice ? (
                        <div className="">
                          <input
                            type="number"
                            name="price"
                            id="price"
                            value={newPrices[index].price}
                            onChange={(e) =>
                              handleChangeProductPrice(
                                product.id,
                                e.target.value
                              )
                            }
                            className="w-24 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
                          />
                        </div>
                      ) : (
                        <p className="text-lg font-bold">
                          {newPrices[index].price.toFixed(2)}€
                        </p>
                      )}

                      {editPrice ? (
                        <button
                          aria-label="back-to-products"
                          onClick={() => setEditPrice(false)}
                        >
                          <FontAwesomeIcon
                            icon={faFloppyDisk}
                            className="w-4 inline-flex"
                            style={{ color: "#97815C" }}
                          />
                        </button>
                      ) : (
                        <button
                          aria-label="back-to-products"
                          onClick={() => setEditPrice(true)}
                        >
                          <FontAwesomeIcon
                            icon={faPenToSquare}
                            className="w-4 inline-flex"
                            style={{ color: "#97815C" }}
                          />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="font-semibold">
                Non hai ancora aggiunto nessun prodotto al carrello
              </p>
            )}
          </div>
          <div className="col-span-full mt-10">
            <label
              htmlFor="description"
              className="font-medium block text-sm leading-6 text-gray-900"
            >
              Testo della mail
            </label>
            <div className="mt-2">
              <textarea
                name="description"
                rows={3}
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="mt-6 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="col-span-full w-full mt-10">
            <label
              htmlFor="price"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Costi di spedizione
            </label>
            <div className="mt-2">
              <input
                type="number"
                name="price"
                id="price"
                value={shippingCost}
                placeholder="€"
                onChange={(e) =>
                  setShippingCost(e.target.value.replace(",", "."))
                }
                className="block w-[150px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-palette-dark sm:text-sm sm:leading-6"
              />
            </div>
            <label
              htmlFor="price"
              className="block text-sm font-medium mt-6 leading-6 text-gray-900"
            >
              Sconto
            </label>
            <div className="relative mt-2 rounded-md shadow-sm w-[150px]">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span className="text-gray-500 sm:text-sm">
                  {discountType === DiscountType.Percentage ? "%" : "€"}
                </span>
              </div>
              <input
                type="number"
                name="discount"
                id="discountInput"
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="0"
                onChange={(e) =>
                  setDiscountValue(e.target.value.replace(",", "."))
                }
              />
              <div className="absolute inset-y-0 right-0 flex items-center">
                <label htmlFor="currency" className="sr-only">
                  Sconto
                </label>
                <select
                  id="discountType"
                  name="discountType"
                  className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
                  onChange={(e) =>
                    setDiscountType(
                      e.target.value === "%"
                        ? DiscountType.Percentage
                        : DiscountType.Amount
                    )
                  }
                >
                  <option>%</option>
                  <option>€</option>
                </select>
              </div>
            </div>
            <div className="mt-10 mx-auto lg:w-2/3 bg-neutral-100 p-4 rounded">
              <div className="flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Totale</p>

                <p className="text-sm font-semibold text-gray-900">
                  {calculateTotal()}€
                </p>
              </div>
              <div className="mt-2 flex items-center justify-between">
                <p className="text-sm font-medium text-gray-900">Iva 22%</p>
                <p className="text-sm font-semibold text-gray-900">
                  {calculateTax()}€
                </p>
              </div>
              <div className="mt-1 flex items-center justify-between">
                <p className="text-sm font-bold text-gray-900">
                  Totale inclusa iva
                </p>
                <p className="text-2xl font-semibold text-gray-900">
                  {calculateTotalWithTax()}€
                </p>
              </div>
              {parseFloat(discountValue) > 0 && (
                <>
                  <div className="mt-1 flex items-center justify-between">
                    <p className="text-sm font-medium text-gray-900">Sconto</p>
                    <p className="text-sm font-semibold text-gray-900">
                      -
                      {discountType === DiscountType.Percentage
                        ? (
                            (parseFloat(calculateTotalWithTax()) *
                              parseFloat(discountValue.replace(",", "."))) /
                            100
                          )
                            .toFixed(2)
                            .replace(".", ",")
                        : parseFloat(discountValue)
                            .toFixed(2)
                            .replace(".", ",")}
                      €
                    </p>
                  </div>
                  <div className="mt-1 flex items-center justify-between">
                    <p className="text-sm font-bold text-gray-900">
                      Totale scontato
                    </p>
                    <p className="text-2xl font-semibold text-gray-900">
                      {calculateTotalDiscounted()}€
                    </p>
                  </div>{" "}
                </>
              )}
            </div>
          </div>
          <button
            type="button"
            className="flex ml-auto mt-10 mb-10 rounded-md bg-palette-primary px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-palette-dark"
            onClick={() => setConfirmQuoteDialog(true)}
          >
            Conferma preventivo
          </button>
        </div>
        <ConfirmDialogComponent
          type={DialogType.Delete}
          title="Elimina preventivo"
          body="Sei sicuro di voler eliminare questo preventivo? Questa azione non può essere annullata."
          open={confirmDeleteDialog}
          onClose={() => setConfirmDeleteDialog(false)}
          onConfirm={onDelete}
        />
        <ConfirmDialogComponent
          type={DialogType.Confirm}
          title="Conferma preventivo"
          body="Sei sicuro di voler confermare questo preventivo? Il cliente riceverà una email con il riepilogo dei prodotti e dei costi totali."
          open={confirmQuoteDialog}
          onClose={() => setConfirmQuoteDialog(false)}
          onConfirm={onConfirm}
        />
      </main>
      <FooterComponent />
    </div>
  );
}
